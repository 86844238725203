import styled from "styled-components";

export const BrandBannerVerticalWrapper = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const BrandSlide = styled.div`
  display: flex;
  width: 25%;
  height: 65px;
  justify-content: center;
  align-items: center;
  padding: 1rem 5px;
  img {
    object-fit: contain;
  }
`;
