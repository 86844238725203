import React, { Fragment, FunctionComponent, useContext, useState } from "react";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { IContext, Context, ILandingEvent } from "src/common";
import { handleFormSubmit } from "src/common/utils/formSubmit";

import * as styles from "./PlanningDateModal.style";

const PlanningDateModal: FunctionComponent<{setIsExpanded: (boolean) => void; isExpanded: boolean; currentDate: string}> = ({ isExpanded, setIsExpanded, currentDate }) => 
{
  const props = useContext(Context);
  const [isHidden, setIsHidden] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const {
    register, handleSubmit, errors, reset 
  } = useForm();
  const footer = props?.Footer;
  const universalTexts = props?.UniversalTexts;
  const planningDateContent = {
    headline: "Terminanfrage",
    text: "Nennen Sie uns Ihren Wunschtermin",
  };
  
  if(isHidden) 
  {
    return null;
  }
  
  const onSubmit = (data): void => 
  {
    // Kontaktformular: d-26922068a30343d98a725c3fdaa663da
    // Terminanfrage: d-deb56cdeb9c34309be7a1a521bda38e6 
    // Reparaturanfrage: d-82dc22fc5dee48db9e177c0140b37441 
    // Produktanfrage: d-ee2129a87d2d4e70bbcf21f1bff6491e
    let title: string | undefined;
    if(typeof window !== "undefined") 
    {
      title = document.title;
    }
        
    handleFormSubmit({
      ...data,
      line: props?.CompanyInformationPrivate?.line,
      subject: `Planungsterminanfrage ${data?.Name ? `von ${data?.Name} ` : ""}${title ? `- ${title}` : ""}`,
      dealerMail: props?.CompanyInformationPublic?.email,
      templateId: "d-deb56cdeb9c34309be7a1a521bda38e6",
      // cc: [{ email: "serviceseite@gedk.de" }]
    }).then(r => 
    {
      reset();
      setSuccess(true);
    });
  };
    
  const timeOptions: React.ReactElement[] = [];

  for (let i = 6; i < 20; i++) {

    let value;
    let value2;

    if(i < 10) 
    {
      value = `0${i}:00`;
      value2 = `0${i}:30`;
    }
    else 
    {
      value = `${i}:00`;
      value2 = `${i}:30`;
    }

    timeOptions.push(
      <Fragment key={i}>
        <option value={value}>{value}</option>
        <option value={value2}>{value2}</option>
      </Fragment>
    );
  }

  const vorname = "Vorname";
  const nachname = "Nachname";
  const datum = "Datum";
  const uhrzeit = "Uhrzeit";
  const email = universalTexts?.eMail || "E-Mailasdasdd";
  const telefon = "Telefon";
  const kommentar = "Kommentar";

  return (
    <div css={styles.wrapper} style={isExpanded ? { transform: "none", bottom: 0 } : {}}>
      
      <div css={styles.topBar} onClick={() => setIsExpanded(!isExpanded)}>
        <svg
          css={styles.closeButton}
          // onClick={() => setIsHidden(true)}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
        </svg>
        <h3>{planningDateContent?.headline}</h3>
      </div>

      <div css={styles.contentArea}>
        <p>{planningDateContent?.text}</p>
        {isSuccess ?
          <form>
            <h1>DANKE!</h1>
              Wir werden uns sobald wie möglich bei Ihnen melden.
          </form>
          :
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div css={styles.formRow}>
                <div>
                  <label htmlFor={"vorname"}>{vorname}</label>
                  <input
                    required={true}
                    type="text"
                    placeholder={vorname}
                    id={"vorname"}
                    name="vorname"
                    ref={register}
                  />
                </div>
                <div>
                  <label htmlFor={"nachname"}>{nachname}</label>
                  <input
                    required={true}
                    type="text"
                    placeholder={nachname}
                    id={"nachname"}
                    name="nachname"
                    ref={register}
                  />
                </div>
              </div>
              <div css={styles.formRow}>
                <div>
                  <label htmlFor={"datum"}>{datum}</label>
                  <input
                    required={true}
                    type="date"
                    placeholder={datum}
                    id={"datum"}
                    name="datum"
                    value={currentDate}
                    ref={register}
                  />
                </div>
                <div>
                  <label htmlFor={"uhrzeit"}>{uhrzeit}</label>
                  <select name={"uhrzeit"} placeholder={uhrzeit} id={"uhrzeit"} ref={register}>
                    {timeOptions}
                  </select>
                </div>
              </div>
              <div css={styles.formRow}>
                <div>
                  <label htmlFor={"email"}>{email}</label>
                  <input
                    required={true}
                    type="email"
                    placeholder={email}
                    id={"email"}
                    name="email"
                    ref={register}
                  />
                </div>
                <div>
                  <label htmlFor={"telefon"}>{telefon}</label>
                  <input
                    required={true}
                    type="text"
                    placeholder={telefon}
                    id={"telefon"}
                    name="telefon"
                    ref={register}
                  />
                </div>
              </div>
              <div css={styles.formRow} style={{ display: "block" }}>
                <label htmlFor={"kommentar"}>{kommentar}</label>
                <textarea
                  rows={8}
                  required={true}
                  name="kommentar"
                  id={"kommentar"}
                  placeholder={kommentar}
                  ref={register}
                />
              </div>
            </div>
            <div css={styles.submitWrapper}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  required={true}
                  type="checkbox"
                  placeholder="Ich akzeptiere die Datenschutzerklärung"
                  name="checkbox"
                  id="checkbox"
                  ref={register({ required: true })}
                />
                <label htmlFor={"checkbox"}>
                  Ich akzeptiere die <a href={`/${props.PageImprint?.slug}`}><span>Datenschutzerklärung</span></a>
                </label>
              </div>
              <button type="submit">{universalTexts?.Submit || "Senden"}&nbsp;&nbsp;<i className="fa fa-chevron-right"/></button>
            </div>
          </form> }
      </div>
    </div>
  );
};

export default PlanningDateModal;
