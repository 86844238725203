import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import BrandBannerVertical from "../BrandBannerVertical";
import { Section, Container } from "../Grid";
import { FooterWrapper, BottomWrapper, LinksWrapper } from "./Footer.style";

const Footer: FunctionComponent = () => {
  const props = useContext(Context);
  const companyInformation = props.CompanyInformationPublic;

  return (
    <Section>
      <FooterWrapper>
        <Container>
          <h2>UNSERE MARKEN</h2>
          <BrandBannerVertical />
          <hr />
          <LinksWrapper>
            <a href="/kontakt">Anfahrt</a>
            <a href="/imprint-datenschutz#Datenschutzerklärung">Datenschutzerklärung</a>
            <a href="/imprint-datenschutz">Impressum</a>
          </LinksWrapper>
          <hr />
          <BottomWrapper>
            <p><b>{getContactCompanyNameShort()}</b></p>
            <p>{companyInformation?.street}</p>
            <p>{companyInformation?.postcode} {companyInformation?.place}</p>
            <p>{companyInformation?.phone} · <a href={`mailto:${companyInformation?.email}`}>E-Mail</a> | <a href="/imprint-datenschutz">Impressum</a> | <a href="/imprint-datenschutz#Datenschutzerklärung">Datenschutzerklärung</a> | <a href="/kontakt">Anfahrt</a></p>
          </BottomWrapper>
        </Container>
      </FooterWrapper>
    </Section>
  );
};

export default Footer;
