// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container } from "../Grid";

export const FooterWrapper = styled.div`
  background-color: #bcbcbc;
  color: #aaa;
  font-weight: 300;
  width: 100%;
  padding: 20px 10px 82px 10px;
  margin: auto;

  ${Container} {
    flex-direction: column;
    align-items: center;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 40px 0;
  }

  h2 {
    color: ${props => props.theme.palette.textColor};
    border-bottom: 1px solid ${props => props.theme.palette.textColor};
    font-weight: 300;
    padding-bottom: 10px;
    margin-bottom: 3rem;
  }

  hr {
    height: 1px;
    width: 100%;
    border: none;
    background-color: ${props => props.theme.palette.textColor};
    margin: 2em 0;
  }
`;

export const LinksWrapper: any = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    display: inline-block;
    cursor: pointer;
    background: ${props => props.theme.palette.textColor};
    line-height: 20px;
    padding: 5px;
    font-size: 13px;
    color: #bcbcbc;
    margin-bottom: 1px;
    border: 1px solid ${props => props.theme.palette.textColor};

    &:hover {
      color: ${props => props.theme.palette.textColor};
      background: transparent;
    }
  }
`;

export const BottomWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "Nunito Sans", sans-serif;
  font-size: 13px;
  color: ${props => props.theme.palette.textColor};

  width: 100%;
  text-align: center;

  p {
    font-family: "Nunito Sans", sans-serif;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 0;

    a {
      color: #00628a;
    }
  }
`;
