import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context } from "src/common";

import { FooterWrapper } from "./Footer.style";
import Navigation from "./Navigation";

interface IFooterLinkInColumnList 
{
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent = () => 
{
  const props = useContext(Context);
  const allFooterLinksForUpperWrapper: IFooterLinkInColumnList[] = [];

  for(const item of props.PrimaryNavigation?.items || []) 
  {
    if(item?.label && item?.link?.[0]?.slug) 
    {
      allFooterLinksForUpperWrapper.push({
        title: item.label || item?.link?.[0]?.nameInNavigation,
        link: item?.link?.[0]?.slug,
        isBold: true
      });
    }

    if(item?.subitems) 
    {
      for(const subItem of item.subitems) 
      {
        if(subItem?.nameInNavigation && subItem?.slug) 
        {
          allFooterLinksForUpperWrapper.push({
            title: subItem?.nameInNavigation,
            link: subItem?.slug,
            isBold: false
          });
        }
      }
    }
  }

  return (
    <FooterWrapper>
      <Navigation items={allFooterLinksForUpperWrapper} />
    </FooterWrapper>
  );
};

export default Footer;
