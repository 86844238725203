import React, { FC, useCallback, useState } from "react";
import { getCurrentNavItem, getHeaderNavItemProps, INavigationItem, INavigationItem_Subitems } from "src/common";

import { NavigationContainer, NavigationLinks, NavigationLink, SubNavigationLinks, Icon, BurguerIcon } from "./Header.styles";

const SubNavitation: FC<{ subitems: INavigationItem_Subitems[]; slug: string }> = ({ subitems, slug }) => (
  <>
    {subitems && (
      <SubNavigationLinks>
        {subitems.map((subItem) => (
          <a key={subItem.slug} href={subItem.slug} data-active={slug === subItem.slug}>
            {subItem.nameInNavigation}
          </a>
        ))}
      </SubNavigationLinks>
    )}
  </>
);

const Navigation: FC<{ items: INavigationItem[]; slug: string }> = ({ items, slug }) => 
{
  const [isMenuActive, toggleMenuActive] = useState(false);
  const [currentLinkItem, toggleShowSubItems] = useState(slug);
  const onToggleShowSubItems = useCallback((link: string) => toggleShowSubItems(
    link === currentLinkItem
      ? null
      : link
  ), [currentLinkItem, toggleShowSubItems]);

  const currentMainNavItem: any = getCurrentNavItem(items, slug);
  return (
    <NavigationContainer active={isMenuActive}>
      <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} />

      <NavigationLinks>
        <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} />

        <ul>
          {items.map((item, index) => 
          {
            const itemProps = getHeaderNavItemProps(item);
            if(!itemProps) { return; }

            const active =
              !!slug && (`${slug}` === `${itemProps.link}` || currentMainNavItem?.link?.[0]?.slug === `${itemProps.link}`);

            return (
              <NavigationLink key={index} active={active}>
                <a href={itemProps.link} onClick={() => onToggleShowSubItems(itemProps.link)}>
                  {item.icon && item.icon[0] && (
                    <Icon
                      src={item.icon?.[0]?.src}
                      alt={"hoverIcon"}
                    />
                  )}

                  {itemProps.title}
                </a>

                {currentLinkItem === itemProps.link && (
                  <SubNavitation subitems={item.subitems} slug={slug} />
                )}
              </NavigationLink>
            );
          })}
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;
