import React, { FC, useCallback, useState } from "react";
import { getCurrentNavItem, getHeaderNavItemProps, INavigationItem, INavigationItem_Subitems } from "src/common";

import { NavigationContainer, NavigationLinks, NavigationLink, SubNavigationLinks, Icon, BurguerIconWrapper, IconWrapper } from "./Header.styles";

export const BurguerIcon: FC<{ onClick: (boolean) => void }> = ({ onClick }) => (
  <BurguerIconWrapper onClick={onClick}>
    <span />
    <span />
    <span />
  </BurguerIconWrapper>
);

const SubNavitation: FC<{ subitems: INavigationItem_Subitems[]; slug: string; isShowSubItems: boolean }> = ({ subitems, slug, isShowSubItems }) => (
  <>
    {subitems && (
      <SubNavigationLinks active={isShowSubItems}>
        {subitems.map((subItem) => (
          <a className="sub-nav" key={subItem.slug} href={`/${subItem.slug}`} data-active={slug === subItem.slug}>
            {subItem.nameInNavigation}
          </a>
        ))}
      </SubNavigationLinks>
    )}
  </>
);

const NavigationItem: FC<{active: boolean; itemProps: Record<string, any>; item: Record<string, any>; slug: string }> = ({
  active, itemProps, item, slug 
}) => 
{
  const [isShowSubItems, toogleShowSubItemsdd] = useState(false);
  return (
    <NavigationLink active={active} isShowSubItems={isShowSubItems}>
      <div style={{display: "flex"}}>
        <a className="nav" href={`/${itemProps.link}`} >
          {item.icon && item.icon[0] && (
            <Icon
              src={item.icon?.[0]?.src}
              alt={"hoverIcon"}
            />
          )}
          {itemProps.title}
        </a>
        {item.subitems?.length > 0 && (
          <IconWrapper onClick={() => toogleShowSubItemsdd(!isShowSubItems)} isShowSubItems={isShowSubItems}>
            <div className="icon">
              <i className="fa fa-chevron-right"/>
            </div>
          </IconWrapper>          
        )}
      </div>
      {(
        <SubNavitation subitems={item.subitems} slug={slug} isShowSubItems={isShowSubItems}/>
      )}
    </NavigationLink>

  );
}; 

const Navigation: FC<{ items: INavigationItem[]; slug: string; isMenuActive: boolean; onToggleMenuActive: Function}> = ({
  items, slug, isMenuActive, onToggleMenuActive 
}) => 
{
  const [currentLinkItem, toggleShowSubItems] = useState(slug);
  const onToggleShowSubItems = useCallback((link: string) => toggleShowSubItems(
    link === currentLinkItem
      ? null
      : link
  ), [currentLinkItem, toggleShowSubItems]);

  const currentMainNavItem: any = getCurrentNavItem(items, slug);

  console.log("isMenuActive", isMenuActive);
  return (
    <NavigationContainer active={isMenuActive}>
      <BurguerIcon onClick={() => onToggleMenuActive(!isMenuActive)} />
      <NavigationLinks>
        <ul>
          {items.map((item, index) => 
          {
            const itemProps = getHeaderNavItemProps(item);
            if(!itemProps) { return; }

            const isActive =
              !!slug && (`${slug}` === `${itemProps.link}` || currentMainNavItem?.link?.[0]?.slug === `${itemProps.link}`);

            return (
              <NavigationItem key={index} active={isActive} itemProps={itemProps} item={item} slug={slug}/>
            );
          })}
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;
