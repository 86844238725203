import React, { FC, Fragment } from "react";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";
import { AddressWrapper } from "./Footer.style";

const LinksSeparator: FC = () => <span>|</span>;

const Address: FC<{ companyInformation: any }> = ({ companyInformation }) => (
  <AddressWrapper>
    <h4>Kontakt</h4>
    <p>
      <div>{getContactCompanyNameShort()}</div>
      <div>{companyInformation?.street}</div>
      <div>{companyInformation?.postcode} {companyInformation?.place}</div>
    </p>
    <p>
      <div>Telefon: {companyInformation?.phone}</div>
      <div>E&#8209;Mail: <a href={`mailto:${companyInformation?.email}`}>{companyInformation?.email}</a></div>
    </p>
    <p>
      <a href="/imprint-datenschutz">Impressum</a><br />
      <a href="/imprint-datenschutz">Datenschutzerklärung</a><br/>
      <a href="/kontakt">Anfahrt</a>
    </p>
  </AddressWrapper>
);

export default Address;