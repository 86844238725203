
import React, { FC, useContext } from "react";
import { Context, IContext } from "src/common";

import { HaderContainer, Logo, HeaderContent } from "./Header.styles";
import Navigation from "./Navigation";

const Header: FC = () => 
{
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  return (
    <HaderContainer>
      <HeaderContent>
        {logo && <a href="/"><Logo src={logo.src} /></a>}
        <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} />
      </HeaderContent>
    </HaderContainer>
  );
};

export default Header;
