import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Container: any = styled.div<{ rows: boolean }>`
  display: flex;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0 auto;
  padding: 0 10px;

  ${({ rows }) => rows && "flex-direction: row;"}

  @media (${BREAKPOINTS_DM.silver_768}){
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Section: any = styled.section`
  width: 100vw;

  @media (${BREAKPOINTS_DM.gold_1024}){
  }
`;

export const ContentWrapper: any = styled.div`
  padding: 0 260px;
`;