// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { Container } from "../Grid";

export const FooterWrapper = styled.div`
  background-image: linear-gradient(#796662,#201916);
  color: #aaa;
  font-weight: 300;
  width: 100%;
  padding: 20px 10px 82px 10px;
  margin: auto;

  ${Container} {
    flex-direction: column;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 20px 0 32px 0;
    ${Container} {
      flex-direction: row;
    }
  }

  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: none;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
  }
`;

export const Logo = styled.a`
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 12 * 5);
  }

  padding: 10px 15px;
  display: inline-block;
  > img {
    display: block;
    max-width: 100%;
  }
`;

export const LinksWrapper: any = styled.div`
    column-count: 3;
    column-gap: 20px;
    margin-bottom: 30px;
    display: none;

    p {
      margin-bottom: 3px;
    }
    a {
      font-size: 13px;

      color: ${({ theme }) => theme.palette.white};
      &:hover {
        opacity: .7;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768}) {
      margin-bottom: 10.5px;
      padding: 5.25px 10.5px;
      display: block;
      
    }
`;

export const Link: any = styled.a`
  display: block;
  color: #aaa;
  line-height: 1.2rem;
  ${({ isBold }) => isBold && 'font-weight: bold;'}
`;

export const AddressWrapper: any = styled.div`
    width: 100%;
    text-align: center;

    @media (${BREAKPOINTS_DM.silver_768}) {
      width: calc(100% / 12 * 3);
      text-align: left;
    }

    padding: 10px 15px;
    p, div, a {
      color: ${({ theme }) => theme.palette.white};
      font-size: 13px;
    }

    a {
      &:hover {
        opacity: .7;
      }
    }

    & > .managingDirectorWrapper {
      display: flex;
      align-items: center;
      p {
        margin-top: 0;
      }
    }

    & > .addressLinksWrapper {
      & > span {
        margin: 0 4px;
      }
    }
`;

export const OpeningHoursWrapper: any = styled.div`
  width: 100%;
  text-align: center;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: calc(100% / 12 * 4);
    text-align: left;
  }

  padding: 10px 15px;

  table {
    width: 100%;
    tr td {
      p {
        width: 100%;
        margin: 0;
        text-align: center;
        @media (${BREAKPOINTS_DM.silver_768}) {
          text-align: left;
        }

      }
    }
  }

  p {
    font-size: 13px;
  }
`;

export const Bottom: any = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: 1px solid #aaa;

  width: 100%;
  text-align: center;
  > div {
    display: flex;
    justify-content: center;
    padding: 10px 15px;
    font-size: 11.7px;
    line-height: 33px;
    > a > img {
      height: 19px;
      margin-left: 5px;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    text-align: left;
    flex-direction: row;

    > div {
      width: 50%;
      justify-content: flex-start;
    }
  }
`;

export const BrandsWrapper: any = styled.div`
  width: 100%;
  height: 80px;
`;
