// @ts-nocheck
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

export const BurguerIcon: any = styled.div`
  height: 44px;
  width: 47px;
  background-position: 0px -305px;
  position: absolute;
  cursor: pointer;
  font-size: 40px;
  color: #6D6E70;
  z-index: 999;
  left: 7px;
  margin: 17px 0 0;
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const HaderContainer = styled.div`
  background-color: #000;
  height: 80px;
  position: fixed;
  z-index: 999;
  width: 100%;

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: relative;
    width: 100%;
    height: 80px;
  }
`;

export const HeaderContent: any = styled.div`
  position: relative;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 64.8%;
    margin: 0px 245px;
  }
`;

export const Logo = styled.img`
  position: absolute;
  left: 10px;
  width: 78.64%;
  max-width: 480px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 38.6%;
    left: 21px;
  }
`;

export const NavigationLink = styled.li<{position: boolean}>`
  background-color: rgb(103, 96, 88); 
  border: 1px solid rgb(57, 53, 49);
  margin-bottom: 1px;
  display: flex;
  color: ${({ position }) => position ? "white" : "black"};
  border-bottom: ${({ position }) => position && "1px solid white"}; 

  > a {
    display: flex;
    font-family: franklin_gothic_fs_bookRg,Helvetica,Arial,sans-serif;
    font-size: 18px;
    align-items: center;
    height: 64px;
    font-weight: 400;
    padding-left: 10.5px;
    line-height: 30px;
    width: fit-content;

    @media (${BREAKPOINTS_DM.silver_768}) {
      color: #F8F8F8;
      line-height: unset;
      padding-left: 10.5px;
      padding-right: 11.5px;
      padding-top: 4px;
      padding-bottom: 4px;
      background-image: none;
      font-size: 15px;
      width: unset;
      height: auto;
      &:hover {
        color: #716558; 
      }
    }
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    border: none;
    background-color: transparent;
  }
`;

export const IconWrapper: any = styled.div`
  color: ${({ position }) => position ? "white" : "black"};
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 99999;
  transform: ${({ position }) => position && "rotate(90deg)"};
  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }
`;

export const Icon: any = styled.img`

`;

export const NavigationLinks = styled.div`
  position: relative;

  background-color: #000;
  display: block;
  flex-wrap: wrap;
  align-items: center;

  transform: translate3d(70vw,0,0);
  transition: transform .8s ease;
  width: 80vw;
  height: 80px;
  box-shadow: -2px -2px 5px rgba(50,50,50,.5);

  ${BurguerIcon} {
    display: none;
  }

  ul {
    padding: 123px 0 0 6px;
    list-style: none;
    overflow-y: auto;
    height: calc(100vh - 64px);
    background-color: rgb(57, 53, 49); 
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    transform: translate3d(0,0,0);;
    right: 0;
    width: 100%;
    height: auto;
    padding: .5em 0;
    box-shadow: none;
    ${NavigationLink} {
      display: flex;
    }

    ${BurguerIcon} {
      display: none;
    }
    i {
      display: none;
    }
    ul {
      background-color: transparent;
      padding: 0;
      list-style: none;
      height: auto;
      display: flex;
      width: 100%;
      overflow-x: hidden;
      flex-wrap: wrap;
    }
  }
`;

export const NavigationContainer: any = styled.div`
  position: absolute;
  display: flex;
  right: 4px;
  top: 0;
  background-color: #000;

  ${({ active }) => active
    ? `
      width: 76vw;
      ${NavigationLinks} {
        ${BurguerIcon} {
          display: block;
          margin-left: 5px;
        }
        transform: translate3d(0,0,0);
      }

      ${BurguerIcon} {
        display: none;
      }
    `
    : `
      width: 15%;
    `
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 58.5%;
    flex-direction: row;
    min-height: 80px;
    align-items: flex-end;
    ${BurguerIcon} {
      display: none;
    }
  }
`;

export const SubItemWrapper: any = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  a {
    background-color: #585858;
    color: #FFFFFF;
    font-family: franklin_gothic_fs_bookRg,Helvetica,Arial,sans-serif; 
    fontSize: 15px;
    font-weight: 400;
    display: block;
    padding: 8px 0 8px 21px;
    margin: 1px 0px;
  }
`;

export const sidebar_SubItem: any = css`
    display: block;
    padding: 10px 0 12px 36px;
    &:last-child {
      margin-bottom: 5px;
    }
`;

export const sidebar_SubItemsWrapper: any = css`
  display: none;
`;

export const sidebar_SubItemsWrapperExpanded: any = css`
    display: block;
    @media (${BREAKPOINTS_DM.silver_768}) {
      display: none;
    }
`;

