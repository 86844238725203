import React, { FC, useContext, useState } from "react";
import { Context, IContext } from "src/common";
import Affix from "src/common/components/Affix";

import { Container } from "../Grid";
import { HaderContainer, Logo } from "./Header.styles";
import Navigation, { BurguerIcon } from "./Navigation";
import SocialLinks from "./SocialLinks";

const Header: FC = () => 
{
  const [isMenuActive, toggleMenuActive] = useState(false);
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];
  return (
    <Affix offset={100}>
      <HaderContainer>
        <Container>
          {logo && <a href="/"><Logo src={logo.src} /></a>}
          <SocialLinks/>
          {!isMenuActive && <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} />}
          <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} isMenuActive={isMenuActive} onToggleMenuActive={toggleMenuActive}/>
        </Container>
      </HaderContainer>
    </Affix>
  );
};

export default Header;
