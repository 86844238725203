import React, { FunctionComponent, useContext } from "react";
import { Context } from "src/common";

import { LinksWrapper, Link } from "./Footer.style";

const Navigation: FunctionComponent<{ items: any[] }> = ({ items }) => 
{
  const props = useContext(Context);
  const impressum = props?.PageImprint;

  return (
    <LinksWrapper>
      {items.map((linkItem, i) => (
        <Link href={linkItem?.link} key={`link-key-${i}`}>
          {linkItem?.title}
        </Link>
      ))}
      <Link href={"/imprint-datenschutz"}>
        {"Impressum"}
      </Link>
    </LinksWrapper>
  );
};

export default Navigation;
