import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";
import { Container } from "../Grid";

const HEIGHT = "130px";
const LOGO_WIDTH = "320px";

export const BurguerIcon: any = styled.div`
  height: 36px;
  width: 57px;
  background-image: url("/images/sprite-s29f1189ba8.png");
  background-position: 0px -146px;
  background-repeat: no-repeat;
  position: absolute;
  top: calc(${HEIGHT} / 2 - 36px / 2);
  cursor: pointer;
  z-index: 999;
  left: 10px;
`;

export const HaderContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.white};
  height: ${HEIGHT};
  z-index: 999;

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: relative;
    justify-content: flex-end;
    ${Container} {
      align-items: flex-end;
      flex-direction: row;
      padding-bottom: 20px;
      position: relative;
    }
  }
`;

export const Logo = styled.img`
  display: block;
  margin-left: 10px;
  width: calc(100% - 58px - 25px);

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-left: unset;
    max-width: ${LOGO_WIDTH};
    width: unset;
  }
`;

export const NavigationLink: any = styled.li<{ active: boolean }>`
  :last-child {
    border-right: none;
  }

  > a {
    color: ${({ active, theme }) => active ? theme.palette.white : theme.palette.primary};
    display: flex;
    font-weight: 700;
    font-size: 18px;
    background-color: ${({ active, theme }) => active ? theme.palette.primary : theme.palette.bgInverted};;
    line-height: calc(${HEIGHT} / 2);
    width: 100%;
    margin-bottom: 2px;

    @media (${BREAKPOINTS_DM.silver_768}) {
      color: ${({ active, theme }) => active ? theme.palette.primary : theme.palette.textColor};
      font-size: 15px;
      line-height: unset;
      padding-left: 21px;
      padding-top: 4px;
      padding-bottom: 4px;

      background-color: transparent;
      wdith: unset;
    }
  }
`;

export const Icon: any = styled.img`

`;

export const NavigationLinks = styled.div`
  position: fixed;

  background-color: ${({ theme }) => theme.palette.white};
  display: block;
  flex-wrap: wrap;
  align-items: center;
  padding: ${HEIGHT} 0 0 5px;

  transform: translate3d(70vw,0,0);
  transition: transform .8s ease;
  width: 70vw;
  height: 100vh;
  box-shadow: -2px -2px 5px rgba(50,50,50,.5);
  z-index: 99;
  ${BurguerIcon} {
    display: none;
    left: 0;
  }

  ul {
    list-style: none;
    overflow-y: auto;
    height: calc(100vh - ${HEIGHT});
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    transform: translate3d(0,0,0);
    right: 0;
    position: unset;
    height: unset;
    width: 100%;
    box-shadow: none;
    ${NavigationLink} {
      display: flex;
    }

    ${BurguerIcon} {
      display: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      height: unset;
      justify-content: flex-end;
    }
  }
`;

export const NavigationContainer: any = styled.div<{ active: boolean }>`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.palette.white};

  ${({ active }) => active
    ? `
      width: 70vw;
      ${NavigationLinks} {
        ${BurguerIcon} {
          display: block;
        }
        transform: translate3d(0,0,0);
      }

      ${BurguerIcon} {
        display: none;
      }
    `
    : `
      width: 77px;
    `
}
  
  @media (${BREAKPOINTS_DM.silver_768}) {
    position: unset;
    width: fit-content;
    margin-left: auto;
    ${BurguerIcon} {
      display: none;
    }
  }
`;

export const SubNavigationLinks: any = styled.div<{ active: boolean }>`

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: none;
  }

  > a {
      color: ${({ theme }) => theme.palette.white };
      display: flex;
      font-weight: 700;
      font-size: 15px;
      padding-left: 10.5px;
      background-color: ${({ active, theme }) => active ? theme.palette.primary : theme.palette.bgInverted};;
      line-height: 31px;
      width: 100%;
      margin-bottom: 2px;
    }

`;
