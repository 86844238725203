import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

import { ContainerLarge } from "../Grid";

const WIDTH = "100vw";

export const BurguerIconWrapper: any = styled.div<{ open?: boolean }>`
  position: absolute;
  top: 38px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
  width: 28px;
  height: 28px;

  ${({ open, theme }) => !open
    ? `
      > span {
        display: block;
        height: 5px;
        width: 24px;
        border-radius: 0;
        background-color: ${theme.palette.textColor};

        &:not(first-child) {
          margin-top: 4px;
        }
      }
    `
    : `

    > span {
      position: absolute;
      top: 12px;
      left: 0;
      display: block;
      height: 5px;
      width: 30px;
      border-radius: 0;
      background: ${theme.palette.textColor};

      &:nth-child(1) {
        transform: rotate(45deg)
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        transform: rotate(-45deg)
      }
    }
  `}


  @media (${BREAKPOINTS_DM.gold_1024}){
    display: none;
  }
`;

export const HaderContainer = styled.div`
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.white};
  width: 100vw;
  z-index: 999;
  font-size: 12px;
  padding: 15px 10px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
    position: relative;
    width: ${WIDTH};
  }

  > a {
    align-self: flex-start;
  }

  ${ContainerLarge} {
    flex-direction: column;
    padding: 0;
  }
`;

export const Logo = styled.img`
  display: block;
  height: 80px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 38px 0 0 60px;
    height: 38px;
    transform: none;
  }
`;

export const SubNavigationLinks: any = styled.div<{ open: boolean }>`
  display: ${({ open }) => open ? "block" : "none"};
  padding: 10px 20px;
  background-color: white;
  > a {
    font-size: 13px;
    color: ${({ theme }) => theme.palette.textColor};
    display: flex;
    font-weight: normal;
    padding-left: 10.5px;
    line-height: 31px;
    width: 100%;

    :hover {
      font-weight: 700;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-top: 30px;
    position: absolute;
  }
`;

export const NavigationLink: any = styled.li<{ active: boolean }>`
  display: block;
  border-top: 1px solid #ccc;
  line-height: 36px;

  :last-child {
    border-right: none;
  }

  :first-child {
    border-top: none;
  }

  width: 100%;

  > a {
    display: inline;
    font-size: 14px;
    padding: 10px 0 8px;
    color: ${({ theme }) => theme.palette.textColorHeadings };
    font-weight: 700;
    width: 100%;

    @media (${BREAKPOINTS_DM.silver_768}) {
      display: block;
      color: ${({ active, theme }) => active ? theme.palette.textColorLight : theme.palette.textColorHeadings};
      line-height: unset;
      padding-left: 10.5px;
      padding-right: 11.5px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-bottom: 2px;

      background-color: transparent;
      border: none;
    }
  }

  > svg {
    background-color: white;
    margin-left: 10px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    border-top: none;
    width: unset;
    :hover {
      ${SubNavigationLinks} {
        display: block;
      }
    }

    > button, > svg {
      display: none;
    }
  }
`;

export const Icon: any = styled.img`

`;

export const NavigationLinks: any = styled.div<{ isMenuActive: boolean }>`
  background-color: ${({ theme }) => theme.palette.white};
  display: flex;
  width: 100%;
  max-width: 1400px;
  ul {
    display: block;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    list-style: none;
    overflow-y: auto;
    margin: 0;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    ${NavigationLink} {
      display: flex;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

export const NavigationContainer: any = styled.div<{ active: boolean }>`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.white};

  ${({ active }) => active
    ? `
      ${NavigationLinks} {
        display: flex;
        margin-top: 10px;
      }
    `
    : `
      ${NavigationLinks} {
        display: none;
      }
    `
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    ${NavigationLinks} {
      display: flex;
      margin-top: 0;
    }
  }
`;
