import React, { FC } from "react";
import { ICompanyInformationPublic } from "src/common";
import RichTextGeneral from "../RichText";
import { OpeningHoursWrapper } from "./Footer.style";
const OpeningHours: FC<{ companyInformation: ICompanyInformationPublic }> = ({ companyInformation }) => (
  <OpeningHoursWrapper>
    {/* <h4>Öffnungszeiten</h4>
    {companyInformation.openingHours && (
      <RichTextGeneral textContent={companyInformation.openingHours} />
    )} */}
  </OpeningHoursWrapper>
);

export default OpeningHours;