import React, { FC } from "react";

import { TermintoolWrapper } from "./Termintool.style";

const Termintool: FC<{onSetShowModal: (boolean) => void; onSetDate: (string) => void }> = ({ onSetShowModal, onSetDate }) => 
  (
    <TermintoolWrapper >
      <div className="icon-clock"/>
      <div className="right-side">
        <h2>Terminanfrage</h2>
        <div className="input-0">
          <input
            type="date"
            placeholder={"datum"}
            id={"datum"}
            name="datum"
            onChange={(e) => onSetDate(e.target.value)}
          />
          <div className="icon" onClick={() => onSetShowModal(true) }><i className="fa fa-chevron-right"/></div>
        </div>
      </div>
    </TermintoolWrapper>
  );

export default Termintool;
