import { css } from "styled-components";

export default css`
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('/fonts/slick.ttf');
}
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    z-index: 2;
    bottom: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}
.slick-dots li
{
    position: relative;
    display: inline-block;
    margin: 4px;
    width: 12px;
    height: 12px;

    button {
      margin: 0;
      color: transparent;
      width: 12px;
      height: 12px;
      cursor: pointer;
      background-color: #666;
      border: 1px solid #ccc;
      border-radius: 50%;
    }

    &.slick-active {
      button {
        border: 3px solid #FFF;
      }
    }
}

.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
`;
