import styled from "styled-components";

export const TermintoolWrapper: any = styled.div`
  background: #000;
  display: flex;
  justify-content: space-between;
  height: 81px;
  padding: 10px 15px;
  position: absolute;
  right: 30px;
  top: 142px;
  width: 291px;

  * {
  color: #fff;
  }

  input {
    color: #777878!important;
    width: 158px;
    height: 30px;
    padding: 3px 5px;
    display: block;
    border: solid white 1px;
    &::placeholder {
      color: #777878!important;
      font-size: 13px;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff!important;
    width: 28px;
    height: 30px;
    border: 1px solid white;
    margin-left: 5px;
  }

  .icon-clock {
    background: transparent url(/images/icon-clock.svg) 0 0 no-repeat;
    background-position-y: 50%;
    background-size: contain;
    width: 61px;
  }

  .input-0 {
    display: flex;
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    margin: 0;
  }
  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
