import React, { FC, useContext } from "react";
import { Context } from "src/common";
import { addHttpsToLinks } from "../../../../../common/utils/addHttpsToLinks";
import * as styles from "./Banner.style";
import BannerLink from "./BannerLink";

const Banner: FC = () => {
  const props = useContext(Context);
  const email = props?.CompanyInformationPublic?.email;
  const facebook = props?.CompanyInformationPublic?.facebook;
  const contactSlug = props.PageContact?.slug;
  return (
    <div css={styles.wrapper}>
      {contactSlug && <BannerLink href={`/${contactSlug}`} className={"phone"} />}
      {email && <BannerLink href={"mailto:" + email} className={"at"} />}
      {contactSlug && <BannerLink href={`/${contactSlug}`} className={"way"} />}
      {facebook && <BannerLink href={addHttpsToLinks(facebook)} className={"fb"} />}
    </div>
  );
};

export default Banner;
