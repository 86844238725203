import React, { FC, Fragment } from "react";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";
import { AddressWrapper } from "./Footer.style";

const LinksSeparator: FC = () => <span>|</span>;

const Address: FC<{ companyInformation: any, items: any[] }> = ({ companyInformation, items }) => (
  <AddressWrapper>
    <div className="managingDirectorWrapper">
    <p><strong>{getContactCompanyNameShort()}</strong></p>
    </div>
    <p>{companyInformation?.street}</p>
    <p>
      {companyInformation?.postcode} {companyInformation?.place}
    </p>
    <div className="addressLinksWrapper">
      Telefon: {companyInformation?.phone} -
      <a style={{ paddingLeft: "5px" }} href={`mailto:${companyInformation?.email}`}> E&#8209;Mail</a>
      <LinksSeparator />

      {items &&
        items.map((item, i) => {
          const slug = item?.slug;
          const nameInNavigation = item?.nameInNavigation;

          if (!slug || !nameInNavigation) {
            return null;
          }

          return (
            <Fragment key={i}>
              <a href={slug}>{nameInNavigation}</a>
              {i !== items.length - 1 && <LinksSeparator />}
            </Fragment>
          );
        })}

      <a style={{ paddingLeft: "5px" }} href="/imprint-datenschutz">Impressum</a><LinksSeparator />
      <a style={{ paddingLeft: "5px" }} href="/imprint-datenschutz">Datenschutzerklärung</a><LinksSeparator />
      <a style={{ paddingLeft: "5px" }} href="/kontakt">Anfahrt</a>
    </div>
  </AddressWrapper>
);

export default Address;