import React, { FC, useContext } from "react";
import { Context } from "src/common";

import Img from "../../../BaseComponents/Img/Img";
import { BrandBannerVerticalWrapper, BrandSlide } from "./BrandBannerVertical.style";

const BrandBannerVertical: FC = () => {
  const props = useContext(Context);
  const allBrands = props.AllBrand;

  if (!allBrands || !allBrands.length) {
    return null;
  }

  return (
    <BrandBannerVerticalWrapper>
      {allBrands.map((company) => company?.logo && (
        <BrandSlide>
          <Img
            src={company?.logo}
            lazyload={false}
            placeholderHeight={"84px"}
            quality={200}
            alt={company?.name || ""}
          />
        </BrandSlide>
      ))}
    </BrandBannerVerticalWrapper>
  );
};

export default BrandBannerVertical;
