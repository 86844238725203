import styled from "styled-components";

export const Container: any = styled.div<{ rows: boolean }>`
  display: flex;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0 auto;

  ${({ rows }) => rows && "flex-direction: row;"}
`;
