import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import { css } from "styled-components";

import colors from "../../../constants/colors";

const gray = "#d3d3d3ff";

export const wrapper: any = css`
    position: fixed;
    bottom: 0px;
    transform: translateY(100%);
    overflow-x: hidden;
    background-color: white;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    // box-shadow: 0 0 40px white;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        height: 650px;
        right: 13vh;
        max-width: 90vw;
        width: 570px;
    }
`;

export const topBar: any = css`
    color: white;
    padding: 15px 38px 18px 13px;
    cursor: pointer;
    height: 62px;
    background-color: #796662;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    h3 {
      color: rgb(255, 255, 255);
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      font-size: 28px;
      font-weight: 300;
      line-height: 32px;
      margin: 0px 30px 0 30px;

    }
    img {
      margin-left: 10px;
      width: 42px;
      height: auto;
      margin-top: 5px;
    }
    @media (${BREAKPOINTS_DM.silver_768})
    {
        img {
          width: 50px;
          margin-left: 15px;
        }
    }
`;

export const closeButton: any = css`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 11;
    cursor: pointer;
    fill: white;
    width: 15px;
`;

export const contentArea: any = css`
    border: solid 1px ${gray};
    overflow-y: auto;
    max-height: 80vh;
    padding: 13px;
    border-bottom: none;
    p {
      border-bottom: solid ${gray} 1px;
      color: #000;
      font-family: arial;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 13px;
      padding-bottom: 20px;
    }
`;

export const formRow: any = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    > div {
      width: 49%;
    }
    label {
      color: #796662;
      cursor: pointer;
      display: block;
      font-family: arial;
      font-size: 14px;
      font-weight: 600;
    }
    input, select, textarea {
      width: 100%;
      height: 24px;
      padding: 3px 2px;
      display: block;
      color: #796662;
      border: solid ${gray} 1px;
      &::placeholder {
        font-size: 13px;
        color: #796662;
      }
    }
    textarea {
      height: 100px;
      resize: vertical;
    }
`;

export const submitWrapper: any = css`
    display: flex;
    flex-direction: column;
    button {
      margin: 15px 0;
      padding: 3px 10px;
      color: #796662;
      width: fit-content;
      border: 1px solid #796662;
      line-height: 22px;
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      font-size: 13px;
      font-weight: 400;
      &:hover {
        background-color: #796662;
        color: #fff;
        * {
        color: #fff; 
      }
      }
    }
    label, a {
      color: #796662;
      font-family: arial;
      cursor: pointer;
      font-size: 16px;
    }
    input {
      margin-right: 3px;
    }
    a {
      span {
        font-weight: 300;
        color: #796662;
      }
    }
    * {
      color: #796662; 
    }
`;
