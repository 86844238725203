import React, { FunctionComponent, useState } from "react";
import { getCurrentNavItem, getHeaderNavItemProps, INavigationItem } from "src/common";

import { NavigationContainer, NavigationLinks, NavigationLink, Icon, BurguerIcon, SubItemWrapper, IconWrapper } from "./Header.styles";

const Items: FunctionComponent<{item: Record<string, any>; itemProps: Record<string, any> }> = ({ itemProps, item }) =>
{
  const [isShowSubItems, setShowSubItems] = useState(false); 

  const { subitems } = item;
  return (
    <>
      <NavigationLink position={isShowSubItems}>
        <a href={itemProps.link}>
          {itemProps.title}
        </a>
        {subitems?.length > 0 && 
            <IconWrapper onClick={() => setShowSubItems(!isShowSubItems)} position={isShowSubItems}>
              <i className="fa fa-chevron-right"/>
            </IconWrapper>
        }
      </NavigationLink>

      {subitems?.length > 0 &&
      <SubItemWrapper>
        {isShowSubItems && subitems.map((subItem, i) =>
          <a key={i} href={`/${subItem.slug}`}>{subItem.nameInNavigation}</a>
        )}
      </SubItemWrapper>}
    </>
  );
};

const Navigation: FunctionComponent<{ items: INavigationItem[]; slug: string }> = ({ items, slug }) => 
{
  const [isMenuActive, toggleMenuActive] = useState(false);
  const currentMainNavItem: any = getCurrentNavItem(items, slug);

  return (
    <NavigationContainer active={isMenuActive}>
      <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)}> 
        <i className="fa fa-align-justify"/>
      </BurguerIcon>

      <NavigationLinks>
        <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)}>
          <i className="fa fa-align-justify"/>
        </BurguerIcon>
        <ul>
          {items.map((item, index) => 
          {
            const itemProps = getHeaderNavItemProps(item);
            if(!itemProps) { return; }
            
            return (
              <Items itemProps={itemProps} item={item} key={`item-key-${index}`}/> 
            );
          })}
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;
