import React, { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled from "styled-components";

import { Container } from "../Grid";

const HEIGHT = "125px";
const WIDTH = "100vw";

export const BurguerIconWrapper: any = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 0 0;
  padding: 8px 10px;
  cursor: pointer;
  z-index: 999;

  > span {
    display: block;
    height: 4px;
    width: 28px;
    border-radius: 0;
    background: #575756;

    &:not(first-child) {
      margin-top: 6px;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}){
    display: none;
  }
`;

export const Logo = styled.img`
  width: 95%;
  display: block;
  max-width: 400px!important;
  max-height: 146px!important;
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 25px 0 0 25px;
  }
`;

export const SubNavigationLinks: any = styled.div<{ active: boolean }>`
  display: ${({ active }) => active ? "flex" : "none"};
  flex-direction: column;
  a.sub-nav {
    color: #575756;
    display: flex;
    padding: 6px 0 6px 35px;
    line-height: 31px;
    width: 100%;
  }

  a.sub-nav:last-child {
    border-bottom: 1px solid #575756;
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    position: absolute;
    display: none;
    width: 280px;
    top: 41px;
    flex-direction: column;
    right: 1px;
    > a.sub-nav {
      color: ${({ theme }) => theme.palette.white};
      display: flex;
      padding: 6px 0 6px 30px;
      line-height: 31px;
      width: 100%;

      &:not(:first-child) {
        border-top: 1px solid rgba(255,255,255,.7);
      }

      :hover {
        color: #00628a;
        padding: 6px 0 6px 20px;

        &:before {
          content: ' » ';
          display: inline;
          height: 10px;
          width: 10px;
        }
      }
    }
    a.sub-nav:last-child {
      border-bottom: none;
    }
  }

`;

export const NavigationLink: any = styled.li<{ active: boolean; isShowSubItems: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: ${({ isShowSubItems }) => isShowSubItems ? "none" : "1px solid #575756;"}
  
  :last-child {
    border-right: none;
  }

  a.nav {
    display: flex;
    padding: 10px 0 14px 17px;
    width: fit-content;
    white-space: nowrap;
    justify-content: flex-start;
    color: #575756;

    @media (${BREAKPOINTS_DM.gold_1024}) {
      color: ${({ active, theme }) => active ? theme.palette.textColorLight : theme.palette.textColorSecondary};
      justify-content: center;
      line-height: unset;
      padding: 10px 8px;
      margin: 0 1px;
      background-color: ${({ theme }) => theme.palette.white};
      border: none;
      width: 100%;
    }
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    :hover {
      ${SubNavigationLinks} {
        display: flex;
        background-color: ${({ theme }) => theme.palette.primary};
      }
  
      > a.sub-nav {
        color: ${({ theme }) => theme.palette.white};
        background-color: ${({ theme }) => theme.palette.primary};
      }
    }
  }
`;

export const Icon: any = styled.img`

`;

export const NavigationLinks: any = styled.div<{ isMenuActive: boolean }>`
  display: flex;
  width: 100%;
  background-color: #fff;
  padding-top: 60px;
  height: 100vh;
  overflow-y: auto;
  ul {
    display: flex;
    flex-direction: column;
    font-size: 17px;
    justify-content: flex-start;
    list-style: none;
    width: 100%;
    margin-left: 0;
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    background-color: transparent;
    padding-top: 0px;
    height: unset;
    overflow-y: unset;
    ul {
      flex-direction: row;
    }
    ${NavigationLink} {
      display: flex;
    }
  }
`;

export const NavigationContainer: any = styled.div<{ active: boolean }>`
  position: absolute;
  right: 0px;
  width: 80%;
  display: flex;
  flex: 1;
  box-shadow: -2px -2px 5px rgb(50 50 50 / 50%);
  transition-property: transform;
  transition-duration: .5s;
  ${({ active }) => active
    ? `
        transform: translate3d(0px,0,0);
        ${BurguerIconWrapper} {
          left: 10px;
        }
    `
    : `
        transform: translate3d(940px,0,0);
    `
}
  @media (${BREAKPOINTS_DM.gold_1024}) {
    right: 17px;
    width: fit-content;
    box-shadow: none;
    transform: translate3d(0px,0,0);
    ${NavigationLinks} {
      display: flex;
    }
  }
`;

export const SocialLinksWrapper: any = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  margin: 16px 32px 0 auto;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 16px 50px 0 auto;
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    margin: 16px 12px 0 auto;
  }
`;

export const SocialLink: any = styled.a`
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  height: 20px;
  margin: 0 4px;
  padding: 1px;
  text-align: center;
  width: 20px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 32px;
    height: 32px;
    font-size: 18px;
    padding: 6px;
    margin: 0 4px;
  }
`;

export const HaderContainer = styled.div<{ affix?: boolean }>`
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  z-index: 999;
  font-size: 12px;
  transition: height .5s, background-color .3s;

  ${Container} {
    position: relative;
    height: 100%;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: fixed;
    height: ${({ affix }) => affix ? "100px" : HEIGHT};
    width: ${WIDTH};

    ${({ affix }) => affix && `
      box-shadow: 0 0 15px rgb(0 0 0 / 20%);
    `}
    
    ${SocialLink} {
      ${({ affix }) => affix && `
        width: 20px;
        height: 20px;
        font-size: 11px;
        padding: 1px;
        margin: 0 4px;
      `}
    }
  }

  ${Logo} {
    ${({ affix }) => affix && `
      @media (${BREAKPOINTS_DM.silver_768}) {
        height: 100px;
        padding: 0;
      }
    `};
  }

  ${NavigationContainer} {
    top: 0;
  }

  @media (${BREAKPOINTS_DM.gold_1024}) {
    background-color: ${({ affix }) => affix ? "white" : "transparent"};
    ${SocialLinksWrapper} {
      ${({ affix }) => affix && "margin: 10px 12px 0 auto;"}
    }
    ${NavigationContainer} {
      top: ${({ affix }) => affix ? "33px" : "82px"};
    }
  }

`;

export const IconWrapper: any = styled.div<{isShowSubItems: boolean}>`
  width: 100%;
  display: flex;
  div.icon {
    margin: 10px 10px 0;
    width: ${({ isShowSubItems }) => isShowSubItems ? "10px" : "15px"};
    height: 20px;
    ${({ isShowSubItems }) => isShowSubItems && "transform: rotate(90deg);"}
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    display: none;
  }
`;
