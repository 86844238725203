import React, { FC } from "react";
import { LinksWrapper, Link } from "./Footer.style";

const Navigation: FC<{ items: any[] }> = ({ items }) => {
  return(
  <LinksWrapper>
    {items?.map((linkItem) => (
      <Link key={linkItem?.link} isBold={linkItem.isBold} href={linkItem?.link}>
        {linkItem?.title}
      </Link>
    ))}
  </LinksWrapper>
)};

export default Navigation;