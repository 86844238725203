import React, { FC, useContext } from "react";
import { Context, IContext } from "src/common";

import { SocialLinksWrapper, SocialLink } from "./Header.styles";

const fixUrl: (url: string) => string = (url) => {
  return /^w{3}\./.test(url) ? `https://${url}` : url; 
};

const SocialLinks: FC = () => 
{
  const p = useContext<IContext>(Context);
  const email = p.CompanyInformationPublic?.email;
  const facebook = p.CompanyInformationPublic?.facebook;
  const instagram = p.CompanyInformationPublic?.instagram;
  const pintrest = p.CompanyInformationPublic?.pintrest;
  const twitter = p.CompanyInformationPublic?.twitter;
  const youtube = p.CompanyInformationPublic?.youtube;
  return (
    <SocialLinksWrapper>
      {facebook && <SocialLink href={fixUrl(facebook)} target="_blank"><i className="fab fa-facebook-f"></i></SocialLink>}
      {pintrest && <SocialLink href={fixUrl(pintrest)} target="_blank"><i className="fab fa-pinterest-p"></i></SocialLink>}
      {twitter && <SocialLink href={fixUrl(twitter)} target="_blank"><i className="fab fa-twitter"></i></SocialLink>}
      {email && <SocialLink href={`mailto:${email}`} target="_blank"><i className="far fa-envelope"></i></SocialLink>}
      {youtube && <SocialLink href={fixUrl(youtube)} target="_blank"><i className="fab fa-youtube"></i></SocialLink>}
      {instagram && <SocialLink href={fixUrl(instagram)} target="_blank"><i className="fab fa-instagram"></i></SocialLink>}
    </SocialLinksWrapper>
  );
};

export default SocialLinks;
