
import React, { FC, useContext, useState } from "react";
import { Context, IContext } from "src/common";

import PlanningDateModal from "../../common/PlanningDateModal";
import { ContainerLarge } from "../Grid";
import Termintool from "../Termintool";
import { HaderContainer, Logo } from "./Header.styles";
import Navigation, { BurguerIcon } from "./Navigation";

const Header: FC = () => 
{
  const [isMenuActive, toggleMenuActive] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const [date, setDate] = useState("");
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];

  return (
    <HaderContainer>
      <Termintool onSetShowModal={setShowModal} onSetDate={setDate}/>
      <ContainerLarge>
        {logo && <a href="/"><Logo src={logo.src} /></a>}
        <BurguerIcon open={isMenuActive} onClick={() => toggleMenuActive(!isMenuActive)} />
        <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} isMenuActive={isMenuActive} />
      </ContainerLarge>
      <PlanningDateModal isExpanded={isShowModal} setIsExpanded={setShowModal} currentDate={date}/>
    </HaderContainer>
  );
};

export default Header;
