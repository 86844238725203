import React, { FunctionComponent, useContext } from "react";
import { Context, LogoBannerHorizontal } from "src/common";

import { Section, Container } from "../Grid";
import Address from "./Address";
import { FooterWrapper, BrandsWrapper } from "./Footer.style";
import Navigation from "./Navigation";
import OpeningHours from "./OpeningHours";
interface IFooterLinkInColumnList {
  title: string;
  link: string;
  isBold: boolean;
}

const Footer: FunctionComponent<{ brands?: boolean }> = ({ brands }) => {
  const props = useContext(Context);
  const companyInformation = props.CompanyInformationPublic;

  return (
    <Section>
      <FooterWrapper>
        <Container>
          {brands && (
            <BrandsWrapper>
              <LogoBannerHorizontal />
            </BrandsWrapper>
          )}
        </Container>
        <Container>
          <Navigation />
          <OpeningHours companyInformation={companyInformation} />
          <Address companyInformation={companyInformation}/>
        </Container>
      </FooterWrapper>
    </Section>
  );
};

export default Footer;
