import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Container: any = styled.div<{ rows: boolean }>`
  display: flex;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0 auto;
  padding: 0 10px;;

  ${({ rows }) => rows && "flex-direction: row;"}

  @media (${BREAKPOINTS_DM.silver_768}){
    padding: 0;
  }
`;

export const ContainerLarge: any = styled(Container)`

  @media (${BREAKPOINTS_DM.silver_768}){
    max-width: 1400px;
  }
`;

export const Section: any = styled.section`
  width: 100vw;

`;
